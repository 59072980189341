<template>
    <div>
        <div class="container fl-eqh-c mt-4 p-5" v-if="loading">
            <loading-animation/>
            Loading...
        </div>

        <div class="container" v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>
            <div class="pt-3 bg-11">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <auction-details-tabs-new :details="auction" class="mt-5"></auction-details-tabs-new>
                        </div>

                        <div class="col-12 mb-3 pt-3">
                            <div class="fl-x-bc py-3 pos-r">

                                <div class="">
                                    <i class="fa fa-database icon-img mr-2" style="color: blue"></i>
                                    <span>Auction Items</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <section>
                                <div class="mb-5" v-for="(i, k) in auction.auction_items" v-bind:key="k">
                                    <div class="card p-4 px-3">
                                        <div class="row">

                                            <div class="col-4">
                                                <h6 class="font-inter-medium fs-lg-1">Item Name </h6>
                                                <p class="fs-lg-1">{{ i.item_name }}</p>
                                            </div>

                                            <div class="col-4">
                                                <h6 class="font-inter-medium fs-lg-1">Total Items </h6>
                                                <p class="fs-lg-1">{{ i.total_item_numbers }}</p>
                                            </div>

                                            <div class="col-4">
                                                <h6 class="font-inter-medium fs-lg-1">State </h6>
                                                <p class="fs-lg-1">{{ i.state }}</p>
                                            </div>

                                            <div class="col-4">
                                                <h6 class="font-inter-medium fs-lg-1">District </h6>
                                                <p class="fs-lg-1">{{ i.district }}</p>
                                            </div>

                                            <div class="col-4">
                                                <h6 class="font-inter-medium fs-lg-1">Pincode </h6>
                                                <p class="fs-lg-1">{{ i.pincode }}</p>
                                            </div>

                                            <div class="col-4">
                                                <h6 class="font-inter-medium fs-lg-1">Item Data Sheet </h6>
                                                <p class="fs-lg-1">
                                                    <btn v-if="i.item_data_sheet"
                                                         @click="setDownload(i.item_data_sheet)"
                                                         text="Download" icon="fa fa-download" class="btn-xs"/>

                                                    <span v-else>Not Uploaded</span>
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div class="col-12" v-if="auction.property_images.length > 0">
                            <div class="fl-x fl-j-c mb-3">
                                <div class="fl-x fl-a-c">
                                    <img class="icon-img mr-2"
                                         src="../../assets/web/auction-page-details/image-symbol.png"
                                         alt="">
                                    <span>Property Images</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-12" v-else>
                            <div class="fl-x fl-j-c">
                                <div class="fl-x fl-a-c">
                                    <img class="icon-img mr-2"
                                         src="../../assets/web/auction-page-details/image-symbol.png"
                                         alt="">
                                    <span>No Property Images</span>
                                </div>
                            </div>
                        </div>

                        <section v-if="auction.property_images.length > 0" class="w-100p">
                            <div class="container">
                                <div class="d-grid d-grid-res m-0">
                                    <div class="fl-2" v-for="(image, id) in auction.property_images" v-bind:key="id">
                                        <lockable-image :locked="false" :img="image" :index="id"
                                                        @locked="showPremiumModal"></lockable-image>
                                    </div>
                                </div>

                                <div class="text-center mt-5 pb-5">
                                    <btn text="View All" design="basic-b"
                                         class="px-5 lego-btn-gray-border font-inter-medium"
                                         size="md" @click="showPremiumModal"
                                         borderRadius="0"/>
                                </div>
                            </div>
                        </section>

                        <div class="col-12" v-if="auction.property_videos.length > 0">
                            <div class="fl-x fl-j-c mb-4 mt-4">
                                <div class="fl-x fl-a-c">
                                    <img class="icon-img mr-2"
                                         src="../../assets/web/auction-page-details/video-symbol.png"
                                         alt="">
                                    <span>Property Videos</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-12" v-else>
                            <div class="fl-x fl-j-c mb-4 mt-4">
                                <div class="fl-x fl-a-c">
                                    <img class="icon-img mr-2"
                                         src="../../assets/web/auction-page-details/video-symbol.png"
                                         alt="">
                                    <span>No Property Videos</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-4 col-md-6" v-for="(video, id) in auction.property_videos"
                             v-bind:key="id">
                            <property-video-card class="mb-3" :index="id" :video-url="video.video" :locked="false"
                                                 :bg-image="video.video_thumbnail.image" @locked="showPremiumModal"
                                                 content="Property details with 360 turn around"></property-video-card>
                        </div>
<!--                        testing-->
<!--                        <div class="col-12 col-lg-4 col-md-6">-->
<!--                            <property-video-card class="mb-3" video-url="video.video" :locked="true"-->
<!--                                                 :bg-image="sampleVideoThumbline" @locked="showPremiumModal"-->
<!--                                                 content="Property details with 360 turn around"></property-video-card>-->
<!--                        </div>-->
<!--                        testing-->
                        <div class="col-12 my-4">
                            <div class="fl-x fl-j-c">
                                <div class="fl-x">
                                    <div @click="showPremiumModal">
                                        <auction-download-button class="mr-3" text="Request Property Data Sheet">
                                            <template #image>
                                                <img src="../../assets/web/auction-page-details/request-data-sheet.png"
                                                     class="img-fluid m-0"
                                                     alt="">
                                            </template>
                                        </auction-download-button>
                                    </div>
                                    <div @click="showPremiumModal">
                                        <auction-download-button class="btn-xs" text="Get Legal Advisory Support">
                                            <template #image>
                                                <img src="../../assets/web/auction-page-details/support.png"
                                                     class="img-fluid m-0"
                                                     alt="">
                                            </template>
                                        </auction-download-button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <map-component :items="auction.auction_items"></map-component>
<!--                            <btn @click="$refs.popUpModal.show()">popUpModal</btn>-->
                        </div>

                        <div class="col-12 my-4">
                            <div class="fl-x fl-j-c pb-4">
                                <div class="fl-x">
                                    <btn text="Back" class="mr-3 px-5" design="basic-b" size="md" borderRadius="0"/>
                                    <btn v-if="auction.data.is_third_party" class="px-5"
                                         design="basic-a" size="md" borderRadius="0"
                                         text="Request for Call Back" @click="$refs.CallbackModal.show()"></btn>
                                    <btn text="Participate" @click="$router.push({path: '/login/'})" class="px-5"
                                         design="basic-a" size="md" borderRadius="0"
                                         v-if="validateDate(auction.auction_details.auction_end_date) &&
                                         !auction.data.is_third_party"/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
        <modal class="c-modal-1 no-close" :no-close-on-backdrop="false"
               title="Fill in your details to request for a callback"
               ref="CallbackModal" width="60r" header-color="">
            <request-callback-form :auction_id="id" @success="requestCallBackSuccess"
                                   @failed="requestCallBackSuccess"
                                   class=""></request-callback-form>
        </modal>

        <modal class="c-modal-1 no-close" :no-close-on-backdrop="true" title=""
               ref="popUpModal" width="45r" header-color="">
            <div class="modal-sm-max-h-70vh thin-scrollbar">
                <div class="text-center mb-3 fl-te-c mr-2 ml-2">
                    <div></div>
                    <img src="../../assets/img/logo/logo-1.png" :width="50" :height="30" alt="">
                    <i @click="$refs.popUpModal.close()" class="fa fa-close"/>
                </div>

                <h6 class="font-weight-bold text-center">Bank e Auctions India.com {{ selected }} Membership</h6>
                <div class="px-3 m-w25 ">
                    <div v-for="(feature, index) in features" :key="index" class="row p-2">
                        <div class="col-10">{{ feature }}</div>
                        <div class="col-2">
                            <div class="fl-x fl-j-c">
                                <i class="fa fa-check text-secondary"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-3 small font-weight-bold pb-3 px-2">

                    <div class="col-12 col-md-3 pr-md-0">
                        <div @click="setAccountDetails('Premium')" v-bind:class="{ 'border-bg-8' : selected === 'Premium'}"
                             class=" stat-card-min-h fl-x-cc b-2 round-1 m-0 p-2 cursor-pointer">Premium<br/>₹ 3000.00/3 Months
                        </div>
                    </div>
                    <div class="col-12 col-md-3 pr-md-0">
                        <div @click="setAccountDetails('Gold')" v-bind:class="{ 'border-bg-8' : selected === 'Gold'}"
                             class=" stat-card-min-h fl-x-cc mt-3 mt-md-0  b-2 round-1 m-0 p-2 cursor-pointer">Gold<br/>₹ 5000.00/6 Months
                        </div>
                    </div>
                    <div class="col-12 col-md-3 pr-md-0">
                        <div @click="setAccountDetails('Platinum')"
                             v-bind:class="{ 'border-bg-8' : selected === 'Platinum'}"
                             class=" stat-card-min-h fl-x-cc mt-3 mt-md-0  b-2 round-1 m-0 p-2 cursor-pointer">Platinum<br/>₹ 10000.00/Annum
                        </div>
                    </div>
                    <div class="col-12 col-md-3 pr-md-0">
                        <div @click="setAccountDetails('Free')"
                             v-bind:class="{ 'border-bg-8' : selected === 'Free'}"
                             class=" stat-card-min-h fl-x-cc mt-3 mt-md-0  b-2 round-1 p-2 cursor-pointer">Free
                        </div>
                    </div>
                    <div class="col-12">
                        <btn v-if="selected !== 'Free'" @click="buyPremium"
                             class="btn-block m-1 mt-3" color="secondary">
                            LOGIN TO UPGRADE<i class="fa fa-chevron-right ml-2"/></btn>
                        <btn v-else @click="registerFree" class="btn-block m-1 mt-3" color="secondary">
                            REGISTER<i class="fa fa-chevron-right ml-2"/></btn>

                    </div>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
import LockableImage from '../../components/auction-details/LockableImage';
import PropertyVideoCard from '../../components/auction-details/PropertyVideoCard';
import AuctionDetailsTabsNew from '../../components/auction-details/AuctionDetailsTabsNew';
import MapComponent from '../../components/auction-details/MapComponent';
import AuctionDownloadButton from '../../components/auction-details/AuctionDownloadButton';
import urls from '../../data/urls';
import axios from 'secure-axios';
import RequestCallbackForm from '../../components/auction/RequestCallbackForm';
import sampleVideoThumbline from '../../assets/web/auction-page-details/video.jpg';
export default {
    name: 'AuctionDetailsPage',

    components: {
        RequestCallbackForm,
        AuctionDownloadButton,
        MapComponent,
        AuctionDetailsTabsNew,
        LockableImage,
        // HeadingWithInfo,
        PropertyVideoCard
    },

    data () {
        return {
            id: this.$route.params.id,
            index: null,
            activeMembershipIdx: null,
            membershipSchemes: [
                {
                    title: 'Basic',
                    amount: '0.00/ Year'
                },
                {
                    title: 'Premium',
                    amount: '300.00/ Month'
                },
                {
                    title: 'Premium',
                    amount: '2500.00/ Year'
                }
            ],

            loading: true,
            error: false,
            auction: null,
            propertyImagesList: [],

            features: [],
            selected: '',
            sampleVideoThumbline: sampleVideoThumbline
        };
    },

    mounted () {
        this.setAccountDetails('Premium');
        this.loadData();
    },

    methods: {
        requestCallBackSuccess () {
            this.$refs.CallbackModal.close();
        },
        buyPremium () {
            this.$refs.popUpModal.close();
            this.$router.push({ path: '/login/#' + this.selected });
        },
        registerFree () {
            this.$refs.popUpModal.close();
            this.$router.push({ path: '/register/' });
        },
        showPremiumModal () {
            this.$refs.popUpModal.show();
        },
        setAccountDetails (account) {
            this.selected = account;
            if (account === 'Premium') {
                this.features = [
                    '3 Months Premium Access',
                    'View All Auction Details ',
                    'Daily Email Alerts',
                    '6 Months of Archives',
                    'Dedicated personalized dashboard including bid history',
                    'Complete photos and videos related to Auctioned items',
                    'Legal advisory related to the auction property (2 nos)'
                ];
            } else if (account === 'Gold') {
                this.features = [
                    '6 Months Premium Access',
                    'View All Auction Details ',
                    'Daily Email Alerts',
                    '1 Year of Archives',
                    'Dedicated personalized dashboard including bid history',
                    'Complete photos and videos related to Auctioned items',
                    'Legal advisory related to the auction property (5 nos)'

                ];
            } else if (account === 'Platinum') {
                this.features = [
                    '12 Months Premium Access',
                    'View All Auction Details',
                    'Daily Email Alerts',
                    'Unlimited Years of Archives',
                    'Dedicated personalized dashboard including bid history',
                    'Complete photos and videos related to Auctioned items',
                    'Legal advisory related to the auction property (12 nos)'

                ];
            } else {
                this.features = [
                    'Daily Email Alerts',
                    'View Partial Auction Details'
                ];
            }
        },
        validateDate (date) {
            const currentDate = new Date();
            if (new Date(date) < currentDate) {
                return false;
            } else {
                return true;
            }
            // return new Date(date) < currentDate;
        },

        loadData () {
            const that = this;
            that.loading = true;
            that.error = false;
            const model = {
                auction: that.$route.params.id,
                app: 'website'
            };
            axios.form(urls.auction.info, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.auction = { ...json.data };
                    that.loading = false;
                    console.log('that.auction : ', that.auction);
                    // set property images list
                    that.auction.property_images.forEach(function (item) {
                        that.propertyImagesList.push(item.image);
                    });
                } else {
                    that.loading = false;
                    that.error = true;
                }
            }).catch(function (exception) {
                console.log('exception at the incident details loading => ', exception);
                that.loading = false;
                that.error = true;
            });
        },

        setDownload (url) {
            window.open(url);
        }
    }
};
</script>

<style scoped>
    .m-w25 {
        min-height: 20rem !important;
    }
</style>

<template>
    <div>
        <div class="card-1 row  bs-10">
            <h5 class="head-1 py-3 p-2 pl-4">Event Details</h5>
            <div class="tab-details-contain">

                <div class="item-bg" v-for="(item,i) in Object.keys(details.event_details)" :key="'E' + i">
                    <div class="cell-item-1 item-bg" v-if="item === 'id'"></div>

                    <div class="cell-item-1 item-bg" v-else-if="item === 'event_bank_details'"></div>

                    <div class="cell-item-1 item-bg" v-else-if="item === 'event_bank'"></div>

                    <div class="cell-item-1 item-bg" v-else>
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e">
                                <div class="data-label text-uppercase capitalize-string">{{ setHeading(item) }}</div>
                                <span class="mx-lg-3 mx-1">:</span>
                            </div>
                        </div>
                        <div class="fl-x fl-j-s item-min-width">
                            <span class="fs-data-item">
                                <b>{{ details.event_details[item] }}</b>
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-1 row  bs-10 mt-lg-4">
            <h5 class="head-1 p-2 py-3 pl-4">Property Details</h5>
            <div class="tab-details-contain">

                <div class="item-bg" v-for="(item,i) in Object.keys(details.property_details)" :key="'P' + i">
                    <div class="cell-item-1" v-if="item === 'id'"></div>

                    <div class="cell-item-1" v-else>
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e">
                                <div class="data-label text-uppercase capitalize-string">{{ setHeading(item) }}</div>
                                <span class="mx-lg-3 mx-1">:</span>
                            </div>
                        </div>
                        <div class="fl-x fl-j-s item-min-width">
                                <span class="fs-data-item">
                                    <b>{{ details.property_details[item] }}</b>
                                </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-1 row  bs-10 mt-lg-4">
            <h5 class="head-1 p-2 py-3 pl-4">Auction Details</h5>
            <div class="tab-details-contain">

                <div class="item-bg" v-for="(item, i) in Object.keys(details.auction_details)" :key="'A' + i">

                    <div v-if="item === 'auction_id'" style="display: none" class="m-0 p-0"></div>
                    <div v-else-if="item === 'auction_key'" style="display: none" class="m-0 p-0"></div>
                    <div style="display: none" v-else-if="item === 'user_status'" class="m-0 p-0"></div>

                    <div class="item-bg" v-else-if="item === 'id'">
                        <div class="cell-item-1">
                            <div class="fl-x fl-j-e item-min-width">
                                <div class="fl-x fl-j-e">
                                    <div class="data-label text-uppercase capitalize-string">Auction Id</div>
                                    <span class="mx-lg-3 mx-1">:</span>
                                </div>
                            </div>
                            <div class="fl-x fl-j-s item-min-width">
                                    <span class="fs-data-item">
                                        <b>
                                            {{ details.auction_details[item] }}
                                        </b>
                                    </span>
                            </div>
                        </div>
                    </div>

                    <div class="item-bg" v-else-if="!isTimeField(item)">
                        <div class="cell-item-1">
                            <div class="fl-x fl-j-e item-min-width">
                                <div class="fl-x fl-j-e">
                                    <div class="data-label text-uppercase capitalize-string">{{
                                        setHeading(item)
                                        }}
                                    </div>
                                    <span class="mx-lg-3 mx-1">:</span>
                                </div>
                            </div>
                            <div class="fl-x fl-j-s item-min-width">
                                <span class="fs-data-item">
                                    <b class="fl-x">
                                        <span
                                            v-if="item === 'reserve_price' || item === 'tender_fee'
                                                || item === 'price_bid' || item === 'increment_value'">₹</span>
                                        <p class="mb-0">{{ details.auction_details[item] }}</p>
                                        <span v-if="item === 'auto_extension_time'"> Mins</span>
                                        <p class="mb-0 ml-2" v-if="item === 'auction_start_date'">{{details.auction_details['auction_start_time']}}</p>
                                        <p class="mb-0 ml-2" v-if="item === 'auction_end_date'">{{details.auction_details['auction_end_time']}}</p>
                                    </b>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="text-center text-primary font-inter-medium my-3 fs-1">EMD Details</div>

                <div class="item-bg" v-for="(item,i) in Object.keys(details.emd_details)" :key="'M' + i">

                    <div class="cell-item-1 item-bg" v-if="item === 'id'"></div>

                    <div class="cell-item-1 item-bg" v-else>
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e">
                                <div class="data-label text-uppercase">{{ item }}</div>
                                <span class="mx-lg-3 mx-1">:</span>
                            </div>
                        </div>
                        <div class="fl-x fl-j-s item-min-width">
                                <span class="fs-data-item">
                                    <b>
                                        <span v-if="item === 'emd_amount'">₹</span> {{ details.emd_details[item] }}
                                    </b>
                                </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-1 row  bs-10 mt-lg-4">
            <h5 class="head-1 p-2 py-3 pl-4">Important Dates & Times</h5>
            <div class="tab-details-contain">

                <div class="item-bg" v-for="(item,i) in Object.keys(details.important_dates)" :key="'D' + i">
                    <div class="cell-item-1" v-if="item === 'id'"></div>
                    <div class="" v-else-if="item === 'auction_end_date_counter_format'"></div>

                    <div class="cell-item-1" v-else-if="!isTimeField(item)">
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e">
                                <div class="data-label text-uppercase capitalize-string">{{ setHeading(item) }}</div>
                                <span class="mx-lg-3 mx-1">:</span>
                            </div>
                        </div>
                        <div class="fl-x fl-j-s item-min-width">
                                <span class="fs-data-item">
                                    <b class="fl-x">
                                       <p class="m-0"> {{ details.important_dates[item] }} </p>
                                        <p class="mb-0 ml-2" v-if="item === 'auction_start_date'">{{details.important_dates['auction_start_time']}}</p>
                                        <p class="mb-0 ml-2" v-if="item === 'auction_end_date'">{{details.important_dates['auction_end_time']}}</p>
                                        <p class="mb-0 ml-2" v-if="item === 'press_release_date'">{{details.important_dates['press_release_time']}}</p>
                                        <p class="mb-0 ml-2" v-if="item === 'property_inspection_from_date'">{{details.important_dates['property_inspection_from_time']}}</p>
                                        <p class="mb-0 ml-2" v-if="item === 'property_inspection_to_date'">{{details.important_dates['property_inspection_to_time']}}</p>
                                        <p class="mb-0 ml-2" v-if="item === 'bid_submission_last_date'">{{details.important_dates['bid_submission_last_time']}}</p>
                                        <p class="mb-0 ml-2" v-if="item === 'bid_opening_date'">{{details.important_dates['bid_opening_time']}}</p>

                                    </b>
                                </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-1 row  bs-10 mt-lg-4">
            <h5 class="head-1 py-3 p-2 pl-4">Auction Related Documents</h5>
            <div class="tab-details-contain pos-r">

                <div class="item-bg" v-for="(item, key) in Object.keys(details.document_details)"
                     v-bind:key="'K' + key">

                    <div class="" v-if="item === 'id'"></div>
                    <div class="" v-else-if="item === 'pancard'"></div>

                    <div class="cell-item-1" v-else>
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e">
                                <div class="data-label text-uppercase capitalize-string">{{ setHeading(item) }}</div>
                                <span class="mx-lg-3 mx-1">:</span>
                            </div>
                        </div>
                        <div class="fl-x fl-j-s item-min-width">
                            <span class="" v-if="item !== 'documents_to_be_uploaded'">
                                <btn text="Download" size="xs" icon="fa fa-download" type="button"
                                     @click="setDownload(details.document_details[item])"/>
                            </span>
                            <span class="" v-else>
                                Pan Card, Annexure 3/Declaration Form, Annexure 2/Details of Bidder, Proof Of Address
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AuctionDetailsTabsNew',
    props: {
        details: {
            type: Object
        }
    },
    data () {
        return {
            timeFieldItems: ['auction_start_time', 'auction_end_time', 'press_release_time', 'property_inspection_from_time',
                'property_inspection_to_time', 'bid_submission_last_time', 'bid_opening_time']
        };
    },
    methods: {
        setDownload (url) {
            window.open(url);
        },
        setHeading (title) {
            if (title === 'declaration_form') {
                return 'bid form';
            } else {
                return title.replaceAll('_', ' ');
            }
        },
        isTimeField (item) {
            return this.timeFieldItems.includes(item);
        }
    }
};
</script>

<style scoped lang="scss">
    .head-1 {
        color: white;
        background-color: midnightblue;
        text-align: center;
        //width: 64rem;
        width: 100%;
    }

    .cell-item {
        display: flex;
        //justify-content: space-between;
        align-items: center;
        font-size: 0.6rem;
        padding-left: var(--spacer-1);
        width: 100%;
        @media (min-width: 992px) {
            padding: 0.875rem 0.3125rem;
            font-size: 0.8rem;
            padding-left: var(--spacer-6) !important;
        }
        //border-left: 1px solid gray;
        //border-right: 1px solid gray;
        //border-top: 1px solid gray;
        position: relative;
        //width: 50%;

    }

    .cell-item-1 {
        display: flex;
        //justify-content: space-between;
        justify-content: center;
        font-size: 0.9rem;
        padding: 0.475rem 0.2125rem;
        @media (min-width: 992px) {
            padding: 0.875rem 0.3125rem;
            width: 50%;
            font-size: 1.1rem !important;
        }
        //border-left: 1px solid gray;
        //border-right: 1px solid gray;
        //border-top: 1px solid gray;
        position: relative;
        width: 100% !important;
    }

    //.item-min-width {
    //    //min-width: 50%;
    //    min-width: 6rem !important;
    //    font-size: 0.8rem;
    //    @media (min-width: 992px) {
    //        min-width: 15rem !important;
    //    }
    //}

    .item-min-width {
        //min-width: 50%;
        width: 11rem !important;
        font-size: 0.7rem;
        @media (min-width: 992px) {
            //min-width: 18rem !important;
            min-width: 36rem !important;
            font-size: 1rem;
        }
    }

    .item-min-width-1 {
        min-width: 90%;
        @media (min-width: 992px) {
            min-width: 16rem;
        }
    }

    .data-label {
        font-weight: 400;
        font-size: 0.48rem;
        font-family: inter-medium;
        @media (min-width: 992px) {
            font-size: 1.2rem;
        }
        //display: inline-flex;
        //width: 70%;
        //min-width: 7rem;
    }

    .fs-data-item {
        font-size: 0.6rem;
        @media (min-width: 992px) {
            font-size: 1.2rem;
        }
    }

    .value-item b {
        font-size: 18px;
    }

    .tab-details-contain {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .item-bg {
            width: 100%;

            &:nth-child(even) {
                background-color: var(--bg-9) !important;
            }
        }
    }

</style>

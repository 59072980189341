<template>
    <div>
        <div v-if="index===0" @click="openVideo" :class="getContainerClass + ' cursor-pointer'"
             :style="{'background-image':'url('+ bgImage +')'}">

            <div class="fl-y fl-a-c fl-j-e h-100 card-text">
                <div class="fl-x fl-a-c w-100">
                    <i class="fa fa-play text-white"></i>
                    <div>Play</div>
                </div>
            </div>
            <div class="fa-3x icon-center"></div>
        </div>
        <div v-else @click="lockedClicked(true)">

            <!--Blurred Image-->
<!--            <img class="img-fluid blur-item w-100 h-100" :src="bgImage" alt="">-->
            <img class="img-fluid blur-item" :src="bgImage" alt="">

            <img src="../../assets/web/home/icons/image-locked.png" class="photo-icon" alt="locked-img" v-if="locked">

            <div v-if="locked">
                <span class="line-d-1 w-40"></span>
                <span class="line-d-2 w-40"></span>
                <span class="line-d-3 w-40"></span>
                <span class="line-d-4 w-40"></span>
            </div>
        </div>
        <img src="../../assets/web/home/icons/image-locked.png" class="photo-icon" alt="locked-img" v-if="locked">
<!--        <div v-if="locked">-->
<!--            <span class="line-d-1 w-50"></span>-->
<!--            <span class="line-d-2 w-50"></span>-->
<!--            <span class="line-d-3 w-50"></span>-->
<!--            <span class="line-d-4 w-50"></span>-->
<!--        </div>-->
    </div>
</template>
<script>
export default {
    name: 'PropertyVideoCard',

    props: {
        locked: {
            type: Boolean,
            default: true
        },

        bgImage: {
            type: String
        },

        content: {
            type: String, default: ''
        },

        index: {
            type: Number
        },

        videoUrl: {
            type: String,
            default: ''
        }
    },

    computed: {
        getContainerClass () {
            if (this.index === 0) {
                return 'px-lg-4 px-2 text-white card-contain bg-cover bg-4';
            } else {
                // return 'px-lg-4 px-2 text-white card-contain bg-cover bg-4 blur-item';
                return 'px-lg-4 px-2 text-white card-contain bg-cover bg-4';
            }
        }
    },

    methods: {
        openVideo () {
            if (this.videoUrl) {
                window.open(this.videoUrl);
            }
        },
        lockedClicked () {
            this.$emit('locked');
        }
    }

};
</script>
<style scoped lang="scss">
    .card-contain {
        position: relative;
        min-height: 20vh;
        margin-bottom: var(--spacer-3);
        /*min-height: 420px;*/
        @media (min-width: 992px) {
            //min-height: 30vh;
            min-height: 35vh;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
            min-height: 18vh;
        }
    }

    .dark-overlay {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.35);
    }

    .card-text {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        bottom: 0.825rem;
    }

    .icon-center {
        transition: all ease-in-out 300ms;
        position: absolute;
        top: calc(50% - 1rem); /* 50% - 3/4 of icon height */
        left: calc(50% - 2rem);
        bottom: 0;
        color: white;
        color: var(--color-secondary);
        background: url("../../assets/web/auction-page-details/video-symbol.png");
        background-size: contain;
        height: 3rem;
        width: 3rem;
        cursor: pointer;
        visibility: visible;
        z-index: 899 !important;
        /*height: unset;*/
        display: block;
    }

    .line-d-1 {
    position: absolute;
    height: 1px;
    background-color: rgba(221, 221, 204, 0.15);
    /*transform: rotate(-45deg);*/
    transform: rotate(-30deg);
    z-index: 899 !important;
    //right: 0;
    //width: 60%;
    //top:28%;
    /*right: -9%;
    width: 60%;
    top: 23%;*/
        right: 0;
    width: 13rem;
    top: 3.5rem;
}

.line-d-2 {
    position: absolute;
    height: 1px;
    background-color: rgba(221, 221, 204, 0.15);
    transform: rotate(30deg);
    z-index: 899 !important;
    left: 0;
    /*left: -9%;
    width: 60%;
    top: 23%;*/
        left: 0;
    width: 12rem;
    top: 3.5rem;
}

.line-d-3 {
    position: absolute;
    height: 1px;
    background-color: rgba(221, 221, 204, 0.15);
    transform: rotate(30deg);
    z-index: 899 !important;
    right: 29px;
    //top:25%;
    /*right: -9%;
    width: 60%;
    bottom: 24%;*/
    right: 0;
    width: 12rem;
    bottom: 4.5rem;
}

.line-d-4 {
    position: absolute;
    height: 1px;
    background-color: rgba(221, 221, 204, 0.15);
    transform: rotate(-30deg);
    z-index: 899 !important;
    left: 0;
    //top:25%;
    /*bottom: 25%;
    left: -9%;
    width: 60%;
    bottom: 24%;*/
    left: 0;
    width: 12rem;
    bottom: 4.5rem;
}

.photo-icon {
    transition: all ease-in-out 300ms;
    position: absolute;
    width: 2rem;
    top: 38%;
    left: 46%;
    bottom: 0;
    color: rgba(221, 221, 204, 0.4);
    cursor: pointer;
    visibility: visible;
    height: unset;
    display: block;
}
</style>

<template>
    <div class="cursor-pointer bs-5 fl-x blue-outline">
        <div class="bg-3 p-lg-2 p-1 bs-r fl-x-cc">
            <i :class="icon" class="text-white"></i>
            <slot name="image"></slot>
        </div>
        <div class="bg-primary p-lg-2 px-lg-4 fs-lg-1 p-1 fl-x-cc">
            <p class="m-0">{{ text }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AuctionDownloadButton',
    props: {
        text: {
            type: String
        },
        icon: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.bs-r {
    box-shadow: 10px 0 5px -2px var(--color-primary);
}

img {
    width: 1.8rem;
    height: auto;
}
.blue-outline{
    //outline: 2px solid #027ff2 !important;
    outline: 1.4px solid rgba(2, 127, 242,0.9) !important;
}
</style>

<template>
    <div class="pos-r w-100 h-100 cursor-pointer">
        <img v-if="index===0" @click="openImage" class="w-100 h-100" :src="img.image" alt="#">

        <div v-else @click="openAuctionPage(true)" class="h-100 w-100">

            <!--Blurred Image-->
            <img class="img-fluid blur-item w-100 h-100" :src="img.image" alt="">

            <img src="../../assets/web/home/icons/image-locked.png" class="photo-icon" alt="locked-img">

            <div v-if="locked">
                <span class="line-d-1 w-50"></span>
                <span class="line-d-2 w-50"></span>
                <span class="line-d-3 w-50"></span>
                <span class="line-d-4 w-50"></span>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    name: 'LockableImage',
    props: {
        locked: {
            type: Boolean,
            default: true
        },

        img: {
            type: [Object, String],
            default: null
        },

        index: {
            type: Number
        }
    },
    methods: {
        openAuctionPage () {
            if (this.index !== 0) {
                this.$emit('locked');
            } else {
                this.$emit('unlocked');
            }
        },
        openImage () {
            if (this.img && this.img.image) {
                window.open(this.img.image);
            }
        }
    }
};
</script>

<style scoped lang="scss">

.line-d-1 {
    position: absolute;
    height: 1px;
    background-color: rgba(221, 221, 204, 0.15);
    transform: rotate(-45deg);
    z-index: 899 !important;
    //right: 0;
    //width: 60%;
    //top:28%;
    right: -9%;
    width: 60%;
    top: 23%;
}

.line-d-2 {
    position: absolute;
    height: 1px;
    background-color: rgba(221, 221, 204, 0.15);
    transform: rotate(45deg);
    z-index: 899 !important;
    left: 0;
    left: -9%;
    width: 60%;
    top: 23%;
}

.line-d-3 {
    position: absolute;
    height: 1px;
    background-color: rgba(221, 221, 204, 0.15);
    transform: rotate(45deg);
    z-index: 899 !important;
    right: 29px;
    //top:25%;
    right: -9%;
    width: 60%;
    bottom: 24%;;
}

.line-d-4 {
    position: absolute;
    height: 1px;
    background-color: rgba(221, 221, 204, 0.15);
    transform: rotate(-45deg);
    z-index: 899 !important;
    left: 0;
    //top:25%;
    bottom: 25%;
    left: -9%;
    width: 60%;
    bottom: 24%;;
}

.photo-icon {
    transition: all ease-in-out 300ms;
    position: absolute;
    width: 2rem;
    top: 44%;
    left: 44%;
    bottom: 0;
    color: rgba(221, 221, 204, 0.4);
    cursor: pointer;
    visibility: visible;
    height: unset;
    display: block;
}

</style>

<template>
    <b-form class="row" :save-url="requestUrl" @success="requestSuccess" @failure="requestFailed"
            v-slot="{model, loading}" :save-params="{auction : auction_id}">
        <div class="col-12 col-lg-12 col-md-6">
            <validated-input v-model="model.name" :disabled="loading" :rules="{required:true}"
                             label="Name"></validated-input>
        </div>
        <div class="col-12 col-lg-12 col-md-6">
            <validated-input type="email" v-model="model.email" :disabled="loading" :rules="{required:true}"
                             label="Email"></validated-input>
        </div>
        <div class="col-12 col-lg-12 col-md-6">
            <validated-input type="number" v-model="model.phone" :disabled="loading"
                             :rules="{required:true, min:10, max:10}"
                             label="Phone"></validated-input>
        </div>
        <div class="col-12 col-lg-12 col-md-6">
            <validated-textarea v-model="model.query" :disabled="loading"
                                label="Query"></validated-textarea>
        </div>
        <div class="col-12 col-lg-12 col-md-6">
            <validated-checkbox v-model="model.request_call_back" :disabled="loading"
                                label="I Agree to be Contacted..."></validated-checkbox>
        </div>
        <div class="col-12 col-lg-12 col-md-6">
            <btn :disabled="!model.request_call_back" text="Request for Callback"></btn>
        </div>
    </b-form>
</template>

<script>
import urls from '../../data/urls';

export default {
    name: 'RequestCallbackForm',
    props: {
        auction_id: {
            type: String
        }
    },
    data () {
        return {
            requestUrl: urls.auction.requestCallBack
        };
    },
    methods: {
        requestSuccess (type) {
            this.$notify('Requested callback support', 'Success', {
                type: 'success'
            });
            this.$emit('success');
        },
        requestFailed (type) {
            this.$notify('Already requested for callback support', 'Message', {
                type: 'success'
            });
            this.$emit('failed');
        }
    }
};
</script>

<style scoped>

</style>
